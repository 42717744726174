import React from 'react'
import Nav from './Nav'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <header>
            <Link to="/">nathan m lee</Link>
            <Nav />
        </header>
    )
}

export default Header