import React from 'react'

function Home() {
    return (
        <div>
            <h1 style={{wordSpacing: "-14px"}}>nathan m lee</h1>
            <p><em>Coming Soon</em></p>
        </div>
    )
}

export default Home